<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <header class="section-header">
          <h3>FAQ</h3>
          <p>
            {{ localStorage.info.faq.description }}
          </p>
        </header>
      </b-col>
      <b-col
        md="8"
        class="box p-4 m-c-auto"
        v-for="faq in localStorage.info.faq.questions"
        :key="faq.question + '-faq'"
      >
        <div header-tag="header" role="tab">
          <b-row>
            <b-col
              class="col-10"
              @click="clicky(faq.question.split(' ').join('-'), $event)"
            >
              {{ faq.question }}
            </b-col>
            <b-col class="col-2 text-right">
              <a
                block
                href="#"
                v-b-toggle="faq.question.split(' ').join('-')"
                class="open"
                @click="toggle(faq.question.split(' ').join('-'), $event)"
              >
                <fa-icon
                  :icon="
                    faq.question.split(' ').join('-') == open
                      ? 'chevron-up'
                      : 'chevron-down'
                  "
                ></fa-icon>
              </a>
            </b-col>
          </b-row>
        </div>
        <b-collapse
          :id="faq.question.split(' ').join('-')"
          :accordion="faq.question"
          role="tabpanel"
          class="text-muted"
        >
          {{ faq.body }}
        </b-collapse>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      open: null
    };
  },
  methods: {
    clicky(id) {
      document.querySelector('[aria-controls="' + id + '"]').click();
    },
    toggle(id, e) {
      e.preventDefault();
      if (this.open == id) return (this.open = null);
      if (this.open) {
        document.querySelector('[aria-controls="' + this.open + '"]').click();
      }
      this.open = id;
      return;
    }
  }
};
</script>
<style scoped>
@media (min-width: 768px) {
  .m-c-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.collapse:not(.show) * {
  opacity: 0;
}
</style>
